import React from 'react';
import styled from 'styled-components';
import FadeInOnVisibility from '../../../common/fade-in-on-visibility';

const Wrap = styled.div`
	margin-bottom: 2rem;
	&:last-child {
		margin-bottom: 0;
	}
`;

const StyledServiceCard = styled.div`
	display: flex;
	margin: 0 auto;
	flex-direction: ${(p) => (p.isReverse ? 'row-reverse' : 'row')};
	transform: translate(${(p) => (p.isReverse ? '2rem' : '-2rem')});
	max-width: 60rem;
	.imgWrap {
		flex: 0 0 40%;
		img {
			width: 100%;
			display: block;
		}
	}
	.body {
		background-color: ${(p) => p.theme.color.primary};
		padding: 0 4rem;
		display: flex;
		align-items: center;
		h3 {
			font-family: ${(p) => p.theme.font.display};
			font-size: 2rem;
			margin-bottom: 1rem;
		}
		p {
			margin-bottom: 2rem;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			list-style-type: disc;
			padding-left: 1rem;
			li {
				flex: 0 0 50%;
				margin-bottom: 1rem;
				font-family: ${(p) => p.theme.font.display};
			}
		}
		.partner-badge-container {
			margin-top: 0.5rem;
		}
	}
	@media ${(p) => p.theme.device.mobile} {
		transform: translate(0);
		flex-direction: column;
		.body {
			padding: 2rem 1rem;
		}
	}
`;

export default ({ image, title, paragraph, list, isReverse, partnerBadge }) => (
	<Wrap>
		<FadeInOnVisibility>
			<StyledServiceCard isReverse={isReverse}>
				<div className="imgWrap">
					<img src={image} alt={title} />
				</div>
				<div className="body">
					<div className="inner">
						<h3>{title}</h3>
						<p>{paragraph}</p>
						<ul>
							{list.map((item, i) => (
								<li key={i}>{item}</li>
							))}
						</ul>
						{!!partnerBadge && (
							<div className="partner-badge-container">
								<div
									className="g-partnersbadge"
									data-agency-id="9453865514"
								></div>
							</div>
						)}
					</div>
				</div>
			</StyledServiceCard>
		</FadeInOnVisibility>
	</Wrap>
);
