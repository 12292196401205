import React from 'react';
import { Link } from 'gatsby';

import Page from '../components/core/page';
import Opener from '../components/common/opener';
import ServiceCard from '../components/pages/services/service-card';
import Closer from '../components/common/closer';

import brandingImage from '../images/services/branding.jpg';
import graphicDesignImage from '../images/services/graphic-design.jpg';
import websitesImage from '../images/services/websites.jpg';
import ecommImage from '../images/services/ecomm.jpg';
import advertisingImage from '../images/services/ads.jpg';

export default () => (
	<Page
		title="Services"
		desc="Our services help our clients at every stage of the business life-cycle. From start-ups to national brands, we help you connect with your customers."
	>
		<Opener title="services" subtitle="ALL KINDS OF STUFF.">
			<p>
				Our services are meant to help our clients through every stage of the
				business life-cycle. From start-ups to national brands, our goal is to
				partner with business and brands to help guide their creative and
				digital efforts and connect them with their customers at multiple points
				on the path to purchase.
			</p>
		</Opener>
		<section>
			<ServiceCard
				image={brandingImage}
				title="BRANDING"
				paragraph="Your brand’s success should be by design. Whether it’s starting a new identity from scratch or refreshing a tired one, we work collaboratively with our clients to help bring their vision to life."
				list={[
					'LOGO DESIGN',
					'NAMING & KEY MESSAGING',
					'IDENTITY SYSTEMS',
					'BRAND COLLATERAL',
					'BRAND GUIDELINES',
					'BRANDED CONTENT',
				]}
				isReverse={false}
			/>
			<ServiceCard
				image={graphicDesignImage}
				title="GRAPHIC DESIGN"
				paragraph="If it involves pencils or pixels, we’ve got you covered. Our team of designers, illustrators and art directors specialize in making your message impossible to ignore and beautiful to behold."
				list={[
					'CREATIVE SERVICES',
					'ILLUSTRATION',
					'ART PRODUCTION',
					'PACKAGING',
					'BUSINESS COLLATERAL',
					'PHOTOGRAPHY',
				]}
				isReverse={true}
			/>
			<ServiceCard
				image={websitesImage}
				title="WEBSITES"
				paragraph="Shake off your website shame; we know all the tricks to make customers click. Big or small, building beautiful, conversion-focused user experiences is our domain."
				list={[
					'RESEARCH & STRATEGY',
					'CONTENT MANAGEMENT',
					'DESIGN & DEVELOPMENT',
					'CUSTOM INTEGRATIONS',
					'REPLATFORMING',
					'HOSTING & MAINTENANCE',
				]}
				isReverse={false}
			/>
			<ServiceCard
				image={ecommImage}
				title="E-COMMERCE"
				paragraph="We’re perfecting the art of ADD TO CART. 
			We provide comprehensive e-commerce strategies combined with world-class UX & UI design to help our clients sell and scale."
				list={[
					'SHOPIFY PARTNERS',
					'RETAIL INTEGRATIONS',
					'FULFILLMENT SOLUTIONS',
					'SEO & UX STRATEGY',
					'PAYMENT PROCESSING',
					'CUSTOMIZED DESIGN',
				]}
				isReverse={true}
			/>
			<ServiceCard
				image={advertisingImage}
				title="ADVERTISING"
				paragraph="You can’t sell it if you don’t tell it. You need to let the world know you exist, and we’re experts in crafting disruptive and compelling campaigns that connect brands with audiences."
				list={[
					'INTEGRATED CAMPAIGNS',
					'DIGITAL ADVERTISING',
					'CREATIVE / ART DIRECTION',
					'PAID MEDIA',
					'COPYWRITING',
					'GOOGLE ADS',
				]}
				isReverse={false}
				partnerBadge={false}
			/>
		</section>
		<Closer>
			<h2>
				Talk is cheap.
				<br />
				<Link to="/work">Wanna see the work?</Link>
			</h2>
		</Closer>
	</Page>
);
